import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import Img from "gatsby-image"
import Hyphenated from "react-hyphen"
import de from "hyphenated-de"
import { injectIntl } from "gatsby-plugin-intl"

const useStyles = makeStyles(theme => ({
  homeIntro: {},
  welcome: {
    marginBottom: 20,
    [theme.breakpoints.up("sm")]: {},
  },
  institute: {
    fontSize: "4rem",
    lineHeight: 1,
    marginBottom: 10,
    [theme.breakpoints.up("sm")]: {
      fontSize: "5rem",
      marginBottom: 40,
    },
  },
  introText: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: 100,
    },
    "& a": {
      color: "#0028A5",
    },
    "& a:visited": {
      color: "#0028A5",
    },
    "& a:hover": {
      color: "#DC6027",
    },
  },
  introImage: {
    width: "100% !important",
    height: "auto",
    marginBottom: 50,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
}))

const HomeIntro = props => {
  const { file, data, intl } = props
  const classes = useStyles()
  console.log(intl)

  return (
    <Grid container>
      <Grid item xs={12} md={6} className={classes.homeIntro}>
        <Typography variant="h2" className={classes.welcome}>
          {data.frontmatter.subtitle}
        </Typography>
        <Typography variant="h1" className={classes.institute}>
          {data.frontmatter.title}
        </Typography>
        <Hyphenated language={intl.local}>
          <Typography
            className={classes.introText}
            variant="h4"
            dangerouslySetInnerHTML={{ __html: data.html }}
          />
        </Hyphenated>
      </Grid>
      <Grid item xs={12} md={6}>
        <Img
          className={classes.introImage}
          fixed={file.childImageSharp.fixed}
          alt="headshot"
        />
      </Grid>
    </Grid>
  )
}

export default injectIntl(HomeIntro)
