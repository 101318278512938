import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/header/seo"
import { makeStyles } from "@material-ui/core/styles"
import { injectIntl } from "gatsby-plugin-intl"
import ToolCategoryList from "../components/tools/toolCategoryList"
import HomeIntro from "../components/fragment/homeIntro"

const useStyles = makeStyles(theme => ({
  container: {
    padding: "50px 20px 50px",
    [theme.breakpoints.up("sm")]: {
      padding: "100px 50px 50px",
    },
  },
}))

const IndexPage = props => {
  const { data } = props
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Home" />
      <div className={classes.container}>
        <HomeIntro file={data.file} data={data.markdownRemark} />
        {data.allMarkdownRemark.group.map(({ fieldValue, edges }) => {
          const category = data.categories.edges.find(
            e => e.node.frontmatter.slug === fieldValue
          )
          return (
            <ToolCategoryList
              title={category.node.frontmatter.title}
              edges={edges}
              category={fieldValue}
              key={fieldValue}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export default injectIntl(IndexPage)

export const query = graphql`
  query($language: String) {
    file(relativePath: { eq: "undraw_team_spirit.png" }) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(filter: { fields: { lang: { eq: $language } } }) {
      group(field: frontmatter___category) {
        fieldValue
        edges {
          node {
            id
            frontmatter {
              title
              subtitle
              licence
              costs
              link
            }
            fields {
              slug
              lang
            }
          }
        }
      }
    }
    categories: allMarkdownRemark(
      filter: {
        fields: { slug: { regex: "/categories/" }, lang: { eq: $language } }
      }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
          }
        }
      }
    }
    markdownRemark(
      frontmatter: { lang: { eq: $language }, slug: { eq: "intro" } }
    ) {
      frontmatter {
        slug
        title
        subtitle
      }
      html
    }
  }
`
