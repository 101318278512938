import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import ToolCard from "./toolCard"
import ListHeading from "../fragment/listHeading"

const useStyles = makeStyles(theme => ({
  toolContainer: { marginBottom: "50px" },
}))

const ToolCategoryList = props => {
  const classes = useStyles()
  const { intl, edges, category, title } = props

  return (
    <div>
      <ListHeading
        title={title}
        link={`/categories/${category}`}
        linkTitle={intl.formatMessage({ id: "see-all" })}
      />
      <Grid container spacing={3} className={classes.toolContainer}>
        {edges.slice(0, 3).map(({ node }) => {
          return (
            <Grid key={node.id} item xs={12} sm={6} lg={4}>
              <ToolCard data={node} />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default injectIntl(ToolCategoryList)
